<template>
  <div class="ape-constructor-trait-card-rarity">
    <IStarStroke
      v-for="item in 3"
      :key="item"
      width="12"
      :class="{ active: item <= rarity }"
    />
  </div>
</template>
<script>
import { defineComponent } from "vue-demi";
import IStarStroke from "@/assets/icons/ape-constructor/ape-constructor-star-stroke.svg?inline"
export default defineComponent({
    props: {
        rarity: Number
    },
    components: { IStarStroke }
})
</script>
<style lang="scss">
.ape-constructor-trait-card-rarity {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        margin: 0 4px;
        color: var(--ape-constructor-gray);
        &.active {
            color: #fff;
            fill: currentColor;
        }
    }
}
</style>
