<template>
  <div
    v-sound.click
    class="ape-constructor-trait-card"
    :class="{
      equipped: isEquipped,
      blocked: isBlocked,
      'is-own': isOwned,
      'reduce-top': reduceTop,
      [isOg ? 'og' : item.rarity]: true,
    }"
  >
    <div v-if="showCategory" class="ape-constructor-trait-card-category">{{ item.categoryName }}</div>
    <div
      v-if="isEquipped"
      class="ape-constructor-trait-card-equipped ape-constructor-trait-card-label"
    >
      <ICheckmark width="14" />Equipped
    </div>
    <div
      v-if="isBlocked"
      class="ape-constructor-trait-card-blocked ape-constructor-trait-card-label"
    >
      <ICross width="14" />Blocked
    </div>
    <div v-if="isNew && !isOwned" class="ape-constructor-trait-card__new">
      <INew width="24" />
    </div>
    <div
      v-if="isNew && isOwned"
      v-tooltip="{ content: 'Recently received', distance: 10 }"
      class="ape-constructor-trait-card__recent"
    >
      <IRecent width="18" />
    </div>
    <div class="ape-constructor-trait-card-counts">
      <div
        v-if="item.offChain > 0"
        v-tooltip="'In Trait Bank'"
        class="ape-constructor-trait-card-offchain-count"
      >
        <IOffChain width="16" />
        <span>{{ item.offChain }}</span>
        <!-- <span>23</span> -->
      </div>
      <div
        v-tooltip="isOwned ? 'In Wallet' : 'Not In Wallet'"
        class="ape-constructor-trait-card-own-count"
        :class="{ own: isOwned }"
      >
        <IWallet width="16" />
        <span v-if="item.balance > 0">{{ item.balance }}</span>
      </div>
    </div>
    <div class="ape-constructor-trait-card-image">
      <img width="300" height="300" :src="image" alt="image">
    </div>
    <div class="ape-constructor-trait-card-name">#{{ id }} {{ name }}</div>    
    <div v-if="mintedCount" class="ape-constructor-trait-card-minted-count">
      Total Minted
      <span>{{ mintedCount }}</span>
    </div>  
    <!-- <ApeConstructorTraitCardRarity :rarity="2" /> -->

    <div v-if="!nonPurchaseable" class="ape-constructor-trait-card-get-ways-wrapper">
      <div class="ape-constructor-trait-card-get-way-title">Get trait:</div>
      <div class="ape-constructor-trait-card-get-ways">
        <a
          v-sound.click
          class="ape-constructor-trait-card-get-way ape-constructor-trait-card-opensea"
          :href="OSLink"
          target="_blank"
          @click.stop
        >
          <IOpenSea width="16" />
        </a>
        <a
          v-if="!isOg"
          v-sound.click
          href="#"
          class="ape-constructor-trait-card-get-way ape-constructor-trait-card-get-way-lootbox"
          @click.prevent.stop="buyTrait"
        >{{ sourceName }}</a>    
        <a
          href="#"
          class="ape-constructor-trait-card-get-way ape-constructor-trait-card-opensea"
          v-if="hasSellOrders"
          @click.prevent.stop="buyFromMarket"
        >
          <ICart width="10" />
        </a> 
        <IInfo
          v-sound.click
          class="ape-constructor-trait-card-get-way-info"
          width="14"
          @click.prevent.stop="openTraitInfo"
        />
      </div>
    </div>

    <div v-if="$slots.actions" class="ape-constructor-trait-card-actions">
      <slot name="actions" />
    </div>
    <div class="ape-constructor-trait-card-rarity">{{ isOg ? 'OG Mint' : lootBoxName }}</div>
  </div>
</template>
<script>
import { computed, defineComponent } from "vue-demi";
import ApeConstructorTraitCardRarity from "./ApeConstructorTraitCardRarity.vue";
import IInfo from "@/assets/icons/ape-constructor/ape-constructor-info.svg?inline";
import ICheckmark from "@/assets/icons/ape-constructor/ape-constructor-checkmark.svg?inline";
import ICross from "@/assets/icons/ape-constructor/ape-constructor-cross.svg?inline";
import IWallet from "@/assets/icons/ape-constructor/ape-constructor-wallet.svg?inline";
import IInWallet from "@/assets/icons/ape-constructor/ape-constructor-in-wallet.svg?inline";
import INotInWallet from "@/assets/icons/ape-constructor/ape-constructor-not-in-wallet.svg?inline";
import ICart from "@/assets/icons/cart.svg?inline"
import { useApeConstructorStore } from "@/store/ape-constructor";
import { useRouter } from "vue-router";
import { ApeTraitRarity } from "@/helpers/ape-constructor/ape-trait-rarity";
import { resizeImageCF } from "@/helpers/resize-image-cloudflare";
import IOpenSea from "@/assets/icons/opensea.svg?inline"
import IOffChain from "@/assets/icons/off-chain.svg?inline"
import INew from "@/assets/icons/new.svg?inline"
import IRecent from "@/assets/icons/recent.svg?inline"
import { useModal } from "@/composables/useModal";
import { ModalName } from "@/components/Modal/modal-name";

import { Config } from "@/config";

export default defineComponent({
  components: { ApeConstructorTraitCardRarity, INew, IRecent, IOffChain, IWallet, IOpenSea, IInWallet, INotInWallet, IInfo, ICart, ICheckmark, ICross },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isEquipped: Boolean,
    showCategory: Boolean,
    reduceTop: Boolean,
  },
  setup(props, { emit }) {
    const store = useApeConstructorStore();
    const image = computed(() =>
      resizeImageCF(props.item.previewImageSrc, "256")
    );
    const openTraitInfo = () => {
      emit("openInfo");
    };
    const name = computed(() =>
      props.item.name.length > 30
        ? props.item.name.slice(0, 30) + "..."
        : props.item.name
    );
    const id = computed(() => props.item.id);
    const isOwned = computed(() => store.isOwnTrait(props.item.id));
    const isBlocked = computed(() => store.isBlockedTrait(props.item.id));
    const router = useRouter();
    const buyTrait = () => {
      if (props.item.source === 'shop') {
        router.push({ name: "TraitShop" });
        return
      }
      if (props.item.source === 'formula') {
        router.push({ name: "Formulas" });
        return
      }
      if (props.item.source === 'achievements') {
        router.push({ name: "Achievements" });
        return
      }
      if (props.item.source === 'upcoming') {
        return
      }
      router.push({ name: "LootBox" });
    };
    const isOg = computed(() => props.item.rarity === ApeTraitRarity.og);
    const nonPurchaseable = computed(() => props.item.non_purchasable);
    const lootBoxName = computed(() =>
      props.item.rarity
        ? props.item.rarity.charAt(0).toUpperCase() + props.item.rarity.slice(1)
        : false
    );
    const mintedCount = computed(() => props.item.total_minted);
    const OSLink = computed(() => Config.OSUrl.apeTraits + props.item.id)
    const isNew = computed(() => props.item.newly_minted)

    const sourceName = computed(() => {
      if (props.item.source === 'shop') {
        return "The Shop"
      }
      if (props.item.source === 'formula') {
        return 'Formula'
      }
      if (props.item.source === 'upcoming') {
        return "Coming Soon"
      }
      if (props.item.source === 'achievements') {
        return "Achievements"
      }
      return 'LootBox'
    })

    const sellOrders = computed(() => [...props.item.sell_orders].sort((a, b) => a.price - b.price))
    const hasSellOrders = computed(() => props.item.sell_orders?.length)
    // const showAdditional = Config.mode !== 'production'
    const buyFromMarket = () => {
      useModal().open({
        name: ModalName.BuyTraitModal, props: {
          sellOrders: sellOrders.value,
          trait: props.item,
          onSuccess: () => {
            store.fetchWalletTraits()
          }
        }
      })
    }

    return {
      sourceName,
      isNew,
      image,
      OSLink,
      buyTrait,
      mintedCount,
      isOg,
      nonPurchaseable,
      lootBoxName,
      openTraitInfo,
      isBlocked,
      isOwned,
      name,
      id,
      hasSellOrders,
      buyFromMarket
    };
  },
});
</script>
<style lang="scss">
.ape-constructor-trait-card {
  padding: 12px;
  padding-top: 36px;
  padding-bottom: 0;
  background-color: #0a0a0a;
  border-radius: 16px;
  box-sizing: border-box;
  text-align: center;
  color: #fff;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: 16px;
    z-index: -2;
    background: #121212;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: -2px;
    right: -2px;
    height: 100%;
    z-index: -1;
    border-radius: 16px;
    background: linear-gradient(to top, var(--rarity-legendary), transparent);
  }
  &.og {
    &::after {
      background: linear-gradient(to top, var(--rarity-common), transparent);
    }
  }
  &.common {
    &::after {
      background: linear-gradient(to top, var(--rarity-common), transparent);
    }
  }
  &.epic {
    &::after {
      background: linear-gradient(to top, var(--rarity-epic), transparent);
    }
  }
  &.legendary {
    &::after {
      background: linear-gradient(to top, var(--rarity-legendary), transparent);
    }
  }
  &.og & {
    &-rarity {
      background-color: var(--rarity-common);
    }
  }
  &.common & {
    &-rarity {
      background-color: var(--rarity-common);
    }
  }
  &.epic & {
    &-rarity {
      background-color: var(--rarity-epic);
    }
  }
  &.legendary & {
    &-rarity {
      background-color: var(--rarity-legendary);
    }
  }
  &__new {
    position: absolute;
    left: -12px;
    top: -10px;
    color: var(--yellow);
  }
  &__recent {
    position: absolute;
    left: -10px;
    top: -8px;
    color: var(--yellow);
    background-color: var(--gray-light);
    padding: 3px;
    box-sizing: border-box;
    border-radius: 50%;
    font-size: 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  &-label {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    left: 12px;

    svg {
      margin-right: 4px;
    }
  }
  &-equipped {
    color: var(--ape-constructor-accent);
  }
  &-blocked {
    color: var(--ape-constructor-accent);
  }
  &-image {
    margin-bottom: 8px;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  &-opensea {
    background-color: var(--color-opensea);
    flex-shrink: 0;
  }
  &-get-ways {
    display: flex;
    align-items: stretch;
    &-wrapper {
      margin-top: 6px;
    }
  }
  &-get-way {
    flex: 1;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #fff;
    text-decoration: none;
    transition: 0.2s;
    &:hover {
      filter: brightness(0.8);
    }
    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:last-of-type {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &-info {
      margin-left: 8px;
    }
    &-title {
      opacity: 0.5;
      text-align: left;
      text-transform: uppercase;
      font-size: 10px;
      margin-bottom: 4px;
    }
    &-lootbox {
      flex: 2;
      background-color: var(--primary);

      flex-shrink: 0;
      min-width: 90px;
    }
  }
  &-rarity {
    font-size: 12px;
    height: 20px;
    box-sizing: border-box;
    text-transform: uppercase;
    color: #fff;
    margin: 0 -12px;
    margin-top: 12px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  &-category {
    background: #232323;
    padding: 4px 8px;
    display: inline-block;
    box-sizing: border-box;
    border-radius: 24px;
    font-size: 12px;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%);
    left: 50%;
    white-space: nowrap;
  }
  &-name {
    font-size: 12px;
    margin-bottom: 4px;
    text-transform: capitalize;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-minted-count {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
    text-align: left;
    margin-bottom: 8px;
    span {
      color: #fff;
    }
  }
  &-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
  }
  &-action {
    padding: 8px;
    flex: 1;
    color: #fff;
    border-radius: 24px;
    font-size: 12px;
    text-decoration: none;
    transition: 0.2s;

    &-buy {
      background-color: var(--primary);
      &:hover {
        filter: brightness(0.8);
      }
    }

    &-info {
      transition: 0.2s;
      margin-left: 8px;
      flex-shrink: 0;
      &:hover {
        filter: brightness(0.8);
      }
    }
  }
  &-counts {
    position: absolute;
    right: 10px;
    top: 14px;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  &-offchain-count {
    margin-right: 5px;
    display: flex;
    align-items: center;
    color: var(--primary);
    svg {
      width: 16px;
    }
    span {
      margin-left: 3px;
    }
  }
  &-own-count {
    display: flex;
    align-items: center;
    svg {
      width: 16px;
    }
    span {
      margin-left: 3px;
    }
    color: var(--ape-constructor-accent);

    &.own {
      color: var(--green);
    }
    &-approved {
      position: absolute;
      left: -2px;
      bottom: 2px;
      color: var(--green);
      border-radius: 50%;
      width: 12px !important;
    }
  }
  &.equipped {
    &::before {
      background: linear-gradient(
        to bottom,
        var(--ape-constructor-accent),
        transparent
      );
    }
    &.is-own {
      &::before {
        background: linear-gradient(
          to bottom,
          var(--ape-constructor-green),
          transparent
        );
      }
    }
  }
  &.reduce-top {
    padding-top: 30px;
  }
  &.reduce-top & {
    &-label {
      top: 10px;
    }
    &-counts {
      top: 9px;
    }
  }
  &.is-own & {
    &-equipped {
      color: var(--ape-constructor-green);
    }
  }
}
</style>
