import { useApi } from "@/composables/useApi";
import { Config } from "@/config";

export const useApiGetApeHelperTraitImages = useApi(
  ({ traitIds, genderId, bodyTypeId }) => ({
    method: "GET",
    url: "/constructor/apes-helper-trait-images",
    baseURL: Config.apeStorageUrl,
    params: {
      "currentTraitIds[]": traitIds,
      genderId,
      furOrSkin: bodyTypeId,
    },
  })
);


