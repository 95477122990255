import { useApi } from "@/composables/useApi";
import { Config } from "@/config";

export const useApiGetApeTraits = useApi(
  ({ categoryId, genderId, bodyTypeId, page }) => ({
    method: "GET",
    url: "/constructor/apes-traits",
    baseURL: Config.apeStorageUrl,
    params: {
      categoryId,
      genderId,
      furOrSkin: bodyTypeId,
      currentPage: page || 1,
    },
  })
);
