export const ApeConstructorCategory = {
  Body: 1,
  Expression: 2,
  Eyes: 3,
  Clothing: 4,
  Head: 5,
  Hair: 6,
  Accesory: 7,
  Necklace: 8,
  Rear_Addon: 9,
  Ear_Rings: 10,
  Eye_Wear: 11,
  Front_Addon: 12,
  Background: 13
};
