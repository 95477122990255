import { useApiGetApeTraitImages } from "@/api/apes/get-trait-images";
import { useApiGetApeCategories } from "@/api/apes/get-categories";
import { defineStore } from "pinia";
import { useApiGetApeTraits } from "@/api/apes/get-traits";
import { useApiGetApeTraitExceptions } from "@/api/apes/get-trait-exceptions";
import { ApeConstructorCategory } from "@/helpers/ape-constructor/ape-constructor-categories";
import { useApiGetApeHelperTraitImages } from "@/api/apes/get-helper-trait-images";
import { useApesStorageApi } from "@/api/useApesStorageApi";
import { Config } from "@/config";
import { useWeb3Store } from "./web3";
import { useToast } from "@/composables/useToast";
import mitt from "mitt";

export const useApeConstructorStore = defineStore("ape-constructor", {
  state: () => ({
    // ape: {
    //   attributes: [
    //     {
    //       trait_type: "Gender",
    //       value: "Female",
    //     },
    //     {
    //       trait_type: "Body Type",
    //       value: "Skin",
    //     },
    //     {
    //       trait_type: "Guild",
    //       value: "North",
    //     },
    //   ],
    // },
    ee: mitt(),
    ape: null,
    defaultTraits: [],
    equippedTraits: [],
    equippedTraitsExceptions: {},
    traitCategories: [],
    helperTraitImages: [],
    helperTraitMasks: {
      bodyMasks: [],
      defaultHairMasks: [],
    },

    traitCategoriesImages: {
      [ApeConstructorCategory.Body]: require("@/assets/img/ape-constructor/categories/Body.jpg"),
      [ApeConstructorCategory.Expression]: require("@/assets/img/ape-constructor/categories/Expression.jpg"),
      [ApeConstructorCategory.Eyes]: require("@/assets/img/ape-constructor/categories/Eyes.jpg"),
      [ApeConstructorCategory.Clothing]: require("@/assets/img/ape-constructor/categories/Clothing.jpg"),
      [ApeConstructorCategory.Head]: require("@/assets/img/ape-constructor/categories/Head.jpg"),

      [ApeConstructorCategory.Hair]: require("@/assets/img/ape-constructor/categories/Hair.jpg"),
      [ApeConstructorCategory.Accesory]: require("@/assets/img/ape-constructor/categories/Accesory.jpg"),
      [ApeConstructorCategory.Necklace]: require("@/assets/img/ape-constructor/categories/Necklace.jpg"),
      [ApeConstructorCategory.Rear_Addon]: require("@/assets/img/ape-constructor/categories/Rear_Addon.jpg"),
      [ApeConstructorCategory.Ear_Rings]: require("@/assets/img/ape-constructor/categories/Ear_Rings.jpg"),
      [ApeConstructorCategory.Eye_Wear]: require("@/assets/img/ape-constructor/categories/Eye_Wear.jpg"),
      [ApeConstructorCategory.Front_Addon]: require("@/assets/img/ape-constructor/categories/Front_Addon.jpg"),
      [ApeConstructorCategory.Background]: require("@/assets/img/ape-constructor/categories/Background.jpg"),
    },   
    // Cached Images
    traitsImages: {},
    // Cached Traits
    traits: {},
    // Cached Exceptions
    traitsExceptions: {},
    loadings: [],
    walletTraits: [],
  }),
  getters: {
    isLoading: (state) => state.loadings.length > 0,

    attributes: (state) => state.ape.attributes,
    genderId() {
      const gender = this.attributes.find(
        (attr) => attr.trait_type === "Gender"
      );
      return gender?.value === "Male" ? 1 : 2;
    },
    guild: (state) => {
      const guild = state.attributes.find(
        (attr) => attr.trait_type === "Guild"
      );
      return guild?.value;
    },
    tokenId: (state) => {
      return state.ape.id;
    },
    originalId: (state) => {
      const oId = state.ape.id % 10000;
      // if id == 10000 oId will be 0
      if (!oId) return 10000;
      return oId;
    },
    bodyTypeId() {
      const equippedTrait = this.equippedTraits.find(
        (trait) => trait.categoryId === ApeConstructorCategory.Body
      );
      if (equippedTrait && equippedTrait.furOrSkin) {
        return equippedTrait.furOrSkin;
      }
      const bodyTypeTrait = this.attributes.find(
        (attr) => attr.trait_type === "Body Type"
      );
      const bodyTypeId = bodyTypeTrait?.value === "Skin" ? 2 : 1;
      return bodyTypeId;
    },
    equippedTraitsImages(state) {
      const guildImages = {
        south: "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/ef3e10cf-1b4e-4454-a476-f72314410d00/original",
        north: "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/370fc539-0d20-409b-0815-b18716464500/original",
        east: "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/6ffdbdaf-3b88-4023-7718-b46cfc686e00/original",
        west: "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/2ffb669e-0737-437b-113c-bf9f5b6f2100/original",
      }
      const images = state.equippedTraits.map((trait) => {
        const imageKey = this.getTraitImageCacheKey(trait);
        return this.traitsImages[imageKey] || [];
      });
      // Remove duplicates for Body and Expression same array
      const items = images.filter(
        (imageArr, index) =>
          images.findIndex((item) => item === imageArr) === index
      );
      const background = [
        {
          imageSrc: "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/73ea4c3c-dfff-46c9-aabf-bae6591d7400/original",
          // imageSrc: require("@/assets/img/ape-constructor/ape-bg.png"),
          zIndex: 0,
        },
      ];
      const guild = [
        {
          imageSrc: guildImages[this.guild.toLowerCase()],
          zIndex: 20,
        },
      ];
      const previewImage = [
        {
          imageSrc: "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/98c21001-f0f9-4e7e-e2b3-6dc40fad0a00/original",
          // imageSrc: require(`@/assets/img/ape-constructor/preview-label.png`),
          zIndex: 1000,
        },
      ];
      const renderImages =  [...items, this.helperTraitImages, background, guild];
      if(this.equippedTraits.findIndex(trait => !this.isOwnTrait(trait.id)) > -1 && Config.mode === 'production') {
        // show preview label
        renderImages.push(previewImage)
      }
      return renderImages
    },
    availableTraits() {
      const items = [...this.defaultTraits, ...this.walletTraits];
      return items.filter(
        (item, index) => items.findIndex((t) => t.id == item.id) === index
      );
    },
    isOwnTrait() {
      return (traitId) => {
        const isAvailable =
          this.availableTraits.findIndex((trait) => trait.id == traitId) > -1;
        return isAvailable;
      };
    },
    isBlockedTrait() {
      return (traitId) => {
        return (this.equippedTraitsExceptions[traitId] || []).length > 0;
      };
    },
    getBlockingTraits() {
      return (traitId) => {
        const equippedBlockingTraits =
          this.equippedTraitsExceptions[traitId] || [];
        return equippedBlockingTraits.map(
          (tId) => this.equippedTraits.find((trait) => trait.id == tId) || {}
        );
      };
    },

    getTraitImageCacheKey() {
      return (trait) => {
        const categoryId = trait.categoryId;
        let cacheKey = `${this.bodyTypeId}-${trait.id}`;
        let additionalTraitId = undefined;
        // For Body and Expression Categories - compoound key

        if (categoryId === ApeConstructorCategory.Body) {
          additionalTraitId = this.equippedTraits.find(
            (trait) => trait.categoryId === ApeConstructorCategory.Expression
          )?.id;
          cacheKey = `BodyExpression${this.bodyTypeId}-${trait.id}-${additionalTraitId}`;
        }
        if (categoryId === ApeConstructorCategory.Expression) {
          additionalTraitId = this.equippedTraits.find(
            (trait) => trait.categoryId === ApeConstructorCategory.Body
          )?.id;
          cacheKey = `BodyExpression${this.bodyTypeId}-${additionalTraitId}-${trait.id}`;
        }
        return cacheKey;
      };
    },
    canDeEquipTrait() {
      const replacableCategories = [
        ApeConstructorCategory.Body,
        ApeConstructorCategory.Expression,
        ApeConstructorCategory.Eyes,
      ];
      return (trait) => {
        return !replacableCategories.includes(trait.categoryId);
      };
    },
  },
  actions: {
    async fetchTraitImages(trait) {
      const traitId = trait.id;
      const categoryId = trait.categoryId;
      let cacheKey = this.getTraitImageCacheKey(trait);
      if (!cacheKey) return;
      let additionalTraitId = undefined;
      // 1 - Body, 2 - Expression
      if (categoryId === ApeConstructorCategory.Body) {
        additionalTraitId = this.equippedTraits.find(
          (trait) => trait.categoryId === ApeConstructorCategory.Expression
        )?.id;
        if (!additionalTraitId) return;
      }
      if (categoryId === ApeConstructorCategory.Expression) {
        additionalTraitId = this.equippedTraits.find(
          (trait) => trait.categoryId === ApeConstructorCategory.Body
        )?.id;
        if (!additionalTraitId) return;
      }

      if (this.traitsImages[cacheKey]) return this.traitsImages[cacheKey];
      const images = await useApiGetApeTraitImages({
        throw: true,
      }).exec({
        bodyTypeId: this.bodyTypeId,
        genderId: this.genderId,
        traitId,
        additionalTraitId,
      });
      this.traitsImages[cacheKey] = images;
    },
    async fetchTraitsImages() {
      const resolvers = this.equippedTraits.map((trait) => {
        return this.fetchTraitImages(trait);
      });
      await Promise.all(resolvers);
    },
    async getCategoryTraits(categoryId, page) {
      const data = await useApiGetApeTraits({ throw: true }).exec({
        categoryId,
        genderId: this.genderId,
        bodyTypeId: this.bodyTypeId,
        page,
      });
      return {
        ...data,
        traits: data.traits.map((trait) => ({
          ...trait,
        })),
      };
    },
    async fetchCategoryTraits(categoryId, page = 1) {
      // const cacheKey = `${categoryId}-${this.bodyTypeId}-${page}`;
      // if (this.traits[cacheKey]) return this.traits[cacheKey];
      const traits = await this.getCategoryTraits(categoryId, page);
      // this.traits[cacheKey] = traits;
      return traits;
    },
    async fetchCategories() {
      let categories = await useApiGetApeCategories({
        throw: true,
        toast: { error: true },
      }).exec();
      categories = categories.map((cat) => {
        return {
          ...cat,
          image: this.traitCategoriesImages[cat.id],
        };
      });
      this.traitCategories = categories;
    },
    async fetchEquipedTraitsExceptions() {
      const ids = [...this.equippedTraits]
        .sort((a, b) => a.id - b.id)
        .map((trait) => trait.id);
      const cacheKey = ids.join("-");
      if (this.traitsExceptions[cacheKey]) {
        this.equippedTraitsExceptions = this.traitsExceptions[cacheKey];
        return;
      }
      const result = await useApiGetApeTraitExceptions({
        throw: true,
      }).exec({
        ids,
      });
      this.equippedTraitsExceptions = result;
      this.traitsExceptions[cacheKey] = result;
    },
    async fetchHelperImages() {
      const { images, bodyMasks, defaultHairMasks } =
        await useApiGetApeHelperTraitImages({
          throw: true,
        }).exec({
          traitIds: this.equippedTraits.map((item) => item.id),
          genderId: this.genderId,
          bodyTypeId: this.bodyTypeId,
        });
      this.helperTraitImages = images || [];
      this.helperTraitMasks = {
        bodyMasks: bodyMasks || [],
        defaultHairMasks: defaultHairMasks || [],
      };
    },

    async fetchWalletTraits() {
      if(useWeb3Store().walletAddress){ 
        const traits = await useApesStorageApi({
          throw: true,
          toast: { error: true },
        }).exec({
          url: "/wallet/traits",
          params: {
            wallet: useWeb3Store().walletAddress,
            network: Config.network.name,
            genderId: this.genderId,
            filters: true,
          },
        });
        this.walletTraits = traits;
      }
    },
    async fetchApeTraits() {
      if (!this.equippedTraits.length) {
        const data = await useApesStorageApi({ throw: true }).exec({
          url: "/metadata/ape-traits",
          params: {
            apeId: this.ape.id,
            network: Config.network.name,
          },
        });
        const defaultTraits = data.attributes.map((attr) => ({
          ...attr,
          name: attr.name || attr.value,
        }));
        this.defaultTraits = defaultTraits;

        const currentlyEquipped = defaultTraits.filter((attr) => !!attr.id);
        this.equippedTraits = currentlyEquipped;
      }

      const resolver = Promise.all([
        this.fetchHelperImages(),
        this.fetchTraitsImages(),
        this.fetchEquipedTraitsExceptions(),
      ]);
      await this.addLoading(resolver);
      this.ee.emit("render");
    },
    resetEquippedTraits() {
      this.equippedTraits = [];
      this.helperTraitImages = [];
    },
    async deEquipTrait(trait) {
      if (!this.canDeEquipTrait(trait)) return;

      this.equippedTraits = this.equippedTraits.filter(
        (t) => t.id !== trait.id
      );
      const resolver = Promise.all([
        this.fetchEquipedTraitsExceptions(),
        this.fetchHelperImages(),
      ]);
      await this.addLoading(resolver);
      this.ee.emit("render");
    },
    toggleTrait(trait) {
      const includesTrait =
        this.equippedTraits.findIndex((t) => t.id === trait.id) > -1;
      if (includesTrait) {
        this.deEquipTrait(trait);
      } else {
        this.equipTrait(trait);
      }
    },
    canEquipTrait(trait) {
      if (!this.isBlockedTrait(trait.id)) return true;

      return false;
    },
    async addLoading(promise) {
      this.loadings.push(promise);
      try {
        await promise;
      } catch (err) {
        throw err;
      } finally {
        this.loadings.splice(this.loadings.indexOf(promise), 1);
      }
    },
    async deEquipBlockingTraits(trait) {
      const blockingTraits = this.getBlockingTraits(trait.id);
      this.equippedTraits = this.equippedTraits.filter(
        (t) => blockingTraits.findIndex((bTrait) => bTrait.id === t.id) < 0
      );
      const resolver = Promise.all([
        this.fetchEquipedTraitsExceptions(),
        this.fetchHelperImages(),
      ]);
      await this.addLoading(resolver);
      await this.equipTrait(trait);
    },
    showBlockedTraitsError(trait, blockingTraits, canResolveConflict) {
      const id = Date.now();
      const linkId = `toast-link-${id}`;

      const toastItem = useToast().error({
        title: "You cannot equip this trait",
        text: `${trait.name} is blocked by ${blockingTraits}
        ${
          canResolveConflict
            ? ` <br> <a id="${linkId}" href="#" class="ape-constructor-toast-link-resolve">Resolve Conflict</a>`
            : ""
        }`,
      });
      setTimeout(() => {
        document.querySelector(`#${linkId}`)?.addEventListener("click", () => {
          toastItem.remove();
          this.deEquipBlockingTraits(trait);
        });
      }, 0);
    },
    async equipTrait(trait) {
      if (!this.canEquipTrait(trait) && Config.mode === 'production') {
        const blockingTraits = this.getBlockingTraits(trait.id);
        const blockingTraitsNames = blockingTraits
          .map((item) => item.name)
          .join(", ");
        const canResolveConflict = blockingTraits
          .map((trait) => this.canDeEquipTrait(trait))
          .every(Boolean);
        this.showBlockedTraitsError(
          trait,
          blockingTraitsNames,
          canResolveConflict
        );
        return;
      }

      // Remove Trait From Same Category
      this.equippedTraits = this.equippedTraits.filter(
        (t) => t.categoryId !== trait.categoryId
      );

      this.equippedTraits.push({ ...trait });

      const resolver = Promise.all([
        this.fetchTraitsImages(),
        this.fetchEquipedTraitsExceptions(),
        this.fetchHelperImages(),
      ]);
      await this.addLoading(resolver);
      this.ee.emit("render");
    },

    async init(ape) {
      this.ape = ape;

      await Promise.all([
        this.fetchCategories(),
        this.fetchWalletTraits(),
        this.fetchApeTraits(),
      ]);
    },
  },
});
