import { useApi } from "@/composables/useApi";
import { Config } from "@/config";

export const useApiGetApeTraitExceptions = useApi(({ ids }) => ({
  method: "GET",
  url: "/constructor/apes-traits-exceptions",
  baseURL: Config.apeStorageUrl,
  params: {
    'traitsIds[]': ids,
  },
}));
