import { useApi } from "@/composables/useApi";
import { Config } from "@/config";

export const useApiGetApeTraitImages = useApi(
  ({ traitId, genderId, bodyTypeId, additionalTraitId }) => ({
    method: "GET",
    url: "/constructor/apes-traits-images",
    baseURL: Config.apeStorageUrl,
    params: {
      additionalTraitId,
      traitId,
      genderId,
      furOrSkin: bodyTypeId,
      // network: Config.network.name
    },
  })
);
